<script>
import KioskAnnouncementModal from "@/views/announcements/sections/KioskAnnouncementModal";
import KioskAnnouncementsService from "@/services/KioskAnnouncementsService";
export default {
  name: 'Announcements',
  components: {KioskAnnouncementModal},
  props: {},
  data() {
    return {
      createModalName: 'modal-create-edit-kiosk-announcement',
      selectedAnnouncement: null,
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 10,
        search: '',
        status: true,
      },
      announcements: [],
      total_count: 0,
      headers: [
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'body',
          label: 'Body',
        },
        {
          key: 'active',
          label: 'Status',
        },
        {
          key: 'start_date',
          label: 'Start Date',
        },
        {
          key: 'end_date',
          label: 'End Date',
        },
      ],
    }
  },
  computed: {
    modalTitle() {
      return this.selectedAnnouncement ? 'Update Kiosk Announcement' : 'Create Kiosk Announcement';
    }
  },
  mounted() {
    this.getAnnouncements();
  },
  methods: {
    async getAnnouncements() {
      try {
        const response = await KioskAnnouncementsService.list(this.filters);
        this.announcements = response.data.data;
        this.filters.page = response.data.meta.page;
        this.filters.page_size = response.data.meta.page_size;
        this.total_count = response.data.meta.total_count;
      } catch (e) {
        const response = e.response;
        let errorText = 'Could not get Kiosk Announcement, please refresh and try again.'
        if (response && response.data.error) {
          errorText = response.data.error;
        }
        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    search() {
      this.currentPage = 1
      this.getMoreAnnouncements(this.currentPage)
    },
    getMoreAnnouncements(page) {
      this.filters.page = page - 1;
      this.getAnnouncements();
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 10,
        search: '',
        status: null,
      }
      this.currentPage = 1;
      this.getAnnouncements();
    },
    showModal() {
      this.selectedAnnouncement = null;
      this.$bvModal.show('modal-create-edit-kiosk-announcement');
    },
    openEditViewModal(item) {
      if (!this.$can('Update', 'KioskAnnouncements')) {
        return;
      }
      this.selectedAnnouncement = item;
      this.$bvModal.show('modal-create-edit-kiosk-announcement');
    },
    closeModals() {
      this.$bvModal.hide('modal-create-edit-kiosk-announcement');
    },
    refreshAnnouncements() {
      this.closeModals();
      this.currentPage = 1
      this.getMoreAnnouncements(this.currentPage);
    },
  },
}
</script>

<template>
  <div>
    <b-card class="" title="Kiosk Announcements">
      <b-btn
        v-if="$can('Create', 'KioskAnnouncements')"
        variant="success"
        class="float-right"
        style="margin-top: -10px;"
        @click="showModal('modal-create-edit-kiosk-announcement')"
      >
        Create Kiosk Announcement
      </b-btn>
      <b-card-text>
        This page shows all Kiosk Announcements and their status
      </b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="row px-2 pt-1 justify-content-end">
        <section class="col-3">
          <label>Search</label>
          <b-input-group>
            <b-form-input v-model="filters.search" class="d-inline-block" placeholder="Search announcement title..."
                          @keydown.enter="search()"
            />
            <b-input-group-append>
              <b-button @click="search()">
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-3">
          <label>Status</label>
          <b-form-select v-model="filters.status" class="mb-2" @change="search()">
            <b-form-select-option :value="null">All</b-form-select-option>
            <b-form-select-option value="true">Active</b-form-select-option>
            <b-form-select-option value="false">Inactive</b-form-select-option>
          </b-form-select>
        </section>

        <div class="ui-spacer"></div>

        <section class="col-3 d-flex justify-content-end align-items-center">
          <b-button @click="clearFilters()">Clear filters</b-button>
        </section>
      </div>

      <section>
        <b-table
          hover
          class="data-table"
          :items="announcements"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
          style="table-layout: fixed; width: 100%;"
          @row-clicked="openEditViewModal"
        >
          <template #cell(title)="data">
            <div class="text-truncate" :title="data.item.title">{{ data.item.title }}</div>
          </template>
          <template #cell(body)="data">
            <div class="text-truncate" :title="data.item.body">{{ data.item.body }}</div>
          </template>
          <template #cell(active)="data">
            <div class="text-truncate" :title="data.item.active ? 'Active' : 'Inactive'">{{ data.item.active ? 'Active' : 'Inactive' }}</div>
          </template>
          <template #cell(start_date)="data">
            {{ data.item.start_date | formatDate }}
          </template>
          <template #cell(end_date)="data">
            {{ data.item.end_date | formatDate }}
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1 mx-2" v-if="total_count > filters.page_size">
            <b-pagination
              v-model="currentPage"
              class="my-0"
              :total-rows="total_count"
              :per-page="filters.page_size"
              @change="getMoreAnnouncements"
            />
          </b-col>
          <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-create-edit-kiosk-announcement" :title="modalTitle" hide-footer no-close-on-backdrop>
      <KioskAnnouncementModal
        :announcement="selectedAnnouncement"
        @close="closeModals"
        @refresh="refreshAnnouncements"
      />
    </b-modal>

  </div>
</template>
